import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
import * as serviceWorker from "./serviceWorker";
import * as $ from "jquery";
import Amplify from "aws-amplify";

import cssOssandon from "./assets/ossandon.css";

window.jQuery = window.$ = $;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:bc3d6cfc-e18a-4c70-80a8-320398a5585a",
    region: "us-east-1",
    userPoolId: "us-east-1_o9vdMIGvk",
    userPoolWebClientId: "2s0f8mjvgus0cajtiqkmdb07fq"
  }
});

const store = configureStore();

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("root")
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
